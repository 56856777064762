import React from 'react';
import { TableCell } from '@mui/material';
import UserLoginTableCell from '@eogile/agrements-common/src/user/list/components/UserLoginTableCell';
import UserLastNameTableCell from '@eogile/agrements-common/src/user/list/components/UserLastNameTableCell';
import UserFirstNameTableCell from '@eogile/agrements-common/src/user/list/components/UserFirstNameTableCell';
import UserEmailTableCell from '@eogile/agrements-common/src/user/list/components/UserEmailTableCell';
import UserProfilesTableCell from '@eogile/agrements-common/src/user/list/components/UserProfilesTableCell';
import UserUpdatedAtTableCell from '@eogile/agrements-common/src/user/list/components/UserUpdatedAtTableCell';
import { PmuUser } from '../../pmuUserTypes';
import { pmuProfileDisplayLabels } from '../../pmuUserUtils';
import UserTableRow from '@eogile/agrements-common/src/user/list/components/UserTableRow';
import DeleteUserAction from '@eogile/agrements-common/src/user/list/components/DeleteUserAction';

type Props = {
  user: PmuUser;
  reloadUsers: () => Promise<void>;
};

const PmuUsersTableRow = ({ user, reloadUsers }: Props) => {
  return (
    <UserTableRow userId={user.id}>
      <UserLoginTableCell user={user} />
      <UserLastNameTableCell user={user} />
      <UserFirstNameTableCell user={user} />
      <UserEmailTableCell user={user} />
      <TableCell>{user.agency?.name}</TableCell>
      <UserProfilesTableCell
        profileCodes={user.profileCodes}
        getProfileName={(profileCode) => pmuProfileDisplayLabels[profileCode]}
      />
      <UserUpdatedAtTableCell user={user} />
      <TableCell>
        <DeleteUserAction user={user} reloadUsers={reloadUsers} />
      </TableCell>
    </UserTableRow>
  );
};

export default PmuUsersTableRow;

import { Dialog, DialogContent, DialogTitle, List, Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { DirectionLabelUsage, ImportResult } from './ImportAgencyTypes';
import { useAppContext } from '../../AppContext';
import { ImportAgencyErrorItem } from './ImportAgencyErrorItem';

type Props = {
  importResult: ImportResult | null;
  opened: boolean;
  onClose: () => void;
};

export const ImportAgencyResultDialog = ({ importResult, opened, onClose }: Props) => {
  const { directionAdmin } = useAppContext();

  if (!importResult || directionAdmin === false) {
    return null;
  }

  return (
    <Dialog open={opened} onClose={onClose} fullWidth>
      <DialogTitle>Résultat de l'import</DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>{directionAdmin.buildDirectionLabel(DirectionLabelUsage.NOMBER_CREATED)}</TableCell>
              <TableCell>{importResult.nbCreated}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{directionAdmin.buildDirectionLabel(DirectionLabelUsage.NOMBER_DELETED)}</TableCell>
              <TableCell>{importResult.nbDeleted}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{directionAdmin.buildDirectionLabel(DirectionLabelUsage.NOMBER_UPDATED)}</TableCell>
              <TableCell>{importResult.nbUpdated}</TableCell>
            </TableRow>
            {importResult.errors.length > 0 && (
              <TableRow>
                <TableCell>Erreurs</TableCell>
                <TableCell>
                  <List>
                    {importResult.errors.map((errorDetail) => (
                      <ImportAgencyErrorItem key={errorDetail.entityName} errorDetail={errorDetail} />
                    ))}
                  </List>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

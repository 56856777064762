import { SectionCard } from '../../components/cards/SectionCard';
import { acquirerSectionTitle, computeCompanyTypes } from '../demandFormUtils';
import { FormGridItem } from '../../components/grid/FormGridItem';
import { EnumSelectAdapter } from '../../form/components/EnumSelectAdapter';
import { AcquirerType } from '../demandTypes';
import { acquirerCompanyTypeLabels, acquirerTypeLabels } from '../demandUtils';
import { RadioGroupAdapter } from '../../form/components/RadioGroupAdapter';
import { useAcquirerFormHelper } from '../hooks/useAcquirerFormHelper';
import { FormGridContainer } from '../../components/grid/FormGridContainer';
import { ReactNode } from 'react';
import { useFormMode } from '../../form/helper/FormModeProvider';
import { useExistingAttachmentsRemovalWarningDisplayHelper } from './ExistingAttachmentsRemovalWarningFieldsProvider';

type Props = {
  supportsEirlFieldForNewDemands: boolean;
  infoTooltips?: {
    acquirerType: ReactNode;
    companyType: ReactNode;
  };
};

export const DemandAcquirerSection = ({ supportsEirlFieldForNewDemands, infoTooltips }: Props) => {
  const { acquirerType, supportsEirlField } = useAcquirerFormHelper(supportsEirlFieldForNewDemands);
  const mode = useFormMode();
  const { getExistingAttachmentsRemovalWarningIfNeeded } = useExistingAttachmentsRemovalWarningDisplayHelper();

  return (
    <SectionCard title={acquirerSectionTitle}>
      <FormGridContainer>
        <FormGridItem>
          <EnumSelectAdapter
            required
            label="Type"
            name="acquirer.type"
            options={Object.values(AcquirerType)}
            optionName={(opt) => acquirerTypeLabels[opt]}
            infoTooltipContent={infoTooltips?.acquirerType}
            warningText={getExistingAttachmentsRemovalWarningIfNeeded('acquirerTypeChange')}
          />
        </FormGridItem>
        {acquirerType === AcquirerType.COMPANY && (
          <FormGridItem>
            <EnumSelectAdapter
              required
              label="Type de société"
              name="acquirer.companyType"
              options={computeCompanyTypes(mode)}
              optionName={(opt) => acquirerCompanyTypeLabels[opt]}
              infoTooltipContent={infoTooltips?.companyType}
            />
          </FormGridItem>
        )}
        {acquirerType === AcquirerType.IN_OWN_NAME && supportsEirlField && (
          <FormGridItem>
            <RadioGroupAdapter
              name="acquirer.eirl"
              label="EIRL ?"
              options={[
                { value: 'true', label: 'Oui' },
                { value: 'false', label: 'Non' },
              ]}
            />
          </FormGridItem>
        )}
      </FormGridContainer>
    </SectionCard>
  );
};

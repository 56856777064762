import { FormGridItem } from '../../../components/grid/FormGridItem';
import { withFieldNamePrefix } from '../../demandFormUtils';
import { FormGridContainer } from '../../../components/grid/FormGridContainer';
import { PhoneFieldAdapter } from '../../../form/components/PhoneFieldAdapter';

export const DemandOutletPhoneNumbers = () => {
  const fieldName = withFieldNamePrefix('outlet.phoneNumbers');
  return (
    <FormGridContainer>
      <FormGridItem>
        <PhoneFieldAdapter name={fieldName('phone1')} label="Téléphone 1" required />
      </FormGridItem>
      <FormGridItem>
        <PhoneFieldAdapter name={fieldName('phone2')} label="Téléphone 2" />
      </FormGridItem>
    </FormGridContainer>
  );
};

import { AppContextType } from '@eogile/agrements-common/src/AppContext';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import {
  currentUserMinistryTerritorialArea,
  ministryProfileDisplayLabels,
} from '@eogile/agrements-common/src/user/userUtils';
import { currentUserAgency, currentUserRegionalDirection, pmuProfileDisplayLabels } from './user/pmuUserUtils';
import { MinistryUserProfile } from '@eogile/agrements-common/src/user/ministryUserTypes';
import { PmuUserProfile } from './user/pmuUserTypes';
import { PmuDemand } from './demand/pmuDemandTypes';
import { PmuDemandForm } from './demand/components/PmuDemandForm';
import { PmuDemandsList } from './demand/PmuDemandsList';
import { CurrentUserDetails } from '@eogile/agrements-common/src/security/types';
import { CurrentPmuApplicationUserDetails } from './security/types';
import { PmuDemandsLoadRequestParams } from './demand/pmuDemandListTypes';
import dayjs from 'dayjs';
import { initialStatusFilterValueIfLocalMinistryUser } from '@eogile/agrements-common/src/demand/demandsFiltersUtils';
import { pmuCanDoDemandActionPostFilter } from './demand/pmuDemandActionRightUtils';
import { pmuAttachmentHelper } from './demand/pmuDemandAttachmentUtils';
import { PmuVerdictInputDialog } from './demand/components/actions/verdictinput/PmuVerdictInputDialog';
import PmuUsersList from './user/list/components/PmuUsersList';
import PmuUserForm from './user/details/components/PmuUserForm';
import { PmuDirectionAdminPage } from './direction/PmuDirectionAdminPage';
import { DirectionLabelUsage } from '@eogile/agrements-common/src/components/direction/ImportAgencyTypes';

const allProfileLabels = { ...ministryProfileDisplayLabels, ...pmuProfileDisplayLabels };
export const PmuContext: AppContextType = {
  appName: 'Outils Agréments',
  login: {
    saml: { samlUserType: UserCompany.PMU },
  },
  profileDisplayLabel: (profile) => allProfileLabels[profile as MinistryUserProfile | PmuUserProfile],
  demand: {
    title: (demand) => {
      const pmuDemand = demand as PmuDemand;
      return `Demande: ${pmuDemand.outlet.name} - ${pmuDemand.outlet.address.zipCode} ${pmuDemand.outlet.address.city}`;
    },
    DemandForm: PmuDemandForm,
    DemandsList: PmuDemandsList,
    canDoActionPostFilter: pmuCanDoDemandActionPostFilter,
    availableCancellationReasons: [],
    attachmentHelper: pmuAttachmentHelper,
    attachmentCanBeAddedAfterValidation: true,
    VerdictInputDialog: PmuVerdictInputDialog,
  },
  menu: {
    demandListQueryParameters: (userDetails: CurrentUserDetails) => {
      const appUserDetails = userDetails as CurrentPmuApplicationUserDetails;
      const params: Partial<Record<keyof PmuDemandsLoadRequestParams, string>> = {
        demandYear: dayjs().year().toString(),
        status: initialStatusFilterValueIfLocalMinistryUser(appUserDetails),
        territorialArea: currentUserMinistryTerritorialArea(appUserDetails),
        agencyCode: currentUserAgency(appUserDetails)?.code,
        regionalDirectionCode: currentUserRegionalDirection(appUserDetails)?.code,
      };
      return params;
    },
  },
  directionAdmin: {
    AppDirectionAdminPage: PmuDirectionAdminPage,
    buildDirectionLabel: (usage) => {
      switch (usage) {
        case DirectionLabelUsage.MENU_TITLE:
          return 'Gestion des zones de vente';
        case DirectionLabelUsage.NOMBER_CREATED:
          return 'Nombre de zones de vente créées';
        case DirectionLabelUsage.NOMBER_UPDATED:
          return 'Nombre de zones de vente mises à jour';
        case DirectionLabelUsage.NOMBER_DELETED:
          return 'Nombre de zones de vente supprimées';
        case DirectionLabelUsage.ERROR_TITLE:
          return 'la zone de vente';
        case DirectionLabelUsage.ERROR_MESSAGE:
          return 'cette zone de vente';
      }
    },
  },
  reassignDemands: {
    cardTitle: 'Ré-affecter les zones de vente',
  },
  users: {
    UsersList: PmuUsersList,
    UserForm: PmuUserForm,
  },
  sessionWatcher: false,
};

import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Card, CardContent, CardHeader, Collapse, Grid } from '@mui/material';
import FormFiltersListener from '@eogile/agrements-common/src/form/components/FormFiltersListener';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import { PmuDemandsFiltersType, PmuDemandsLoadRequestParams } from '../pmuDemandListTypes';
import CollapsableCardHeaderAction from '@eogile/agrements-common/src/components/card/CollapsableCardHeaderAction';
import DateRangePickerAdapter from '@eogile/agrements-common/src/form/components/DateRangePickerAdapter';
import { useDataLoader } from '@eogile/agrements-common/src/hooks/useDataLoader';
import SelectAdapter from '@eogile/agrements-common/src/form/components/SelectAdapter';
import { DemandStatus } from '@eogile/agrements-common/src/demand/demandTypes';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import { usePmuAuthenticatedCurrentUser } from '../../security/hooks';
import dayjs from 'dayjs';
import { pmuDemandsFiltersValidator } from './pmuDemandsFiltersUtils';
import { demandStatusLabels } from '@eogile/agrements-common/src/demand/demandUtils';
import { fixedLength, onlyDigits } from '@eogile/agrements-common/src/form/helper/validators';
import { PmuAgency, PmuRegionalDirection } from '../../common-types/direction/pmuDirectionTypes';
import MinistryTerritorialAreaSelect from '@eogile/agrements-common/src/components/ministry-territorial-area/ministryTerritorialAreaSelect';
import { allPmuDemandStatuses } from '../pmuDemandUtils';

type PmuDemandsFiltersProps = {
  initialValues: PmuDemandsFiltersType;
  formValuesToQueryString: (values: PmuDemandsLoadRequestParams) => Record<string, string>;
};

const PmuDemandsFiltersFields = ({ initialValues, formValuesToQueryString }: PmuDemandsFiltersProps) => {
  const { userDetails } = usePmuAuthenticatedCurrentUser();
  const defaultOpened = Object.values(initialValues).some(
    (value) => Boolean(value) && value !== dayjs().year().toString(),
  );
  const [opened, setOpened] = useState(defaultOpened);

  const { data: agencyList } = useDataLoader<PmuAgency[]>('api/agencies');
  const { data: regionalDirectionList } = useDataLoader<PmuRegionalDirection[]>('api/regional-directions');

  const toggleOpened = () => {
    setOpened(!opened);
  };

  if (agencyList === null || regionalDirectionList === null) {
    return null;
  }

  const agencyOptions = agencyList.map((agency) => ({ id: agency.code, name: agency.name }));
  const statusOptions = allPmuDemandStatuses
    // If the user belongs to the ministry, requests with the "CREATED" status cannot be displayed
    .filter((p) => (userDetails.company === UserCompany.MINISTRY ? p !== DemandStatus.CREATED : true))
    .map((s) => ({ id: s, name: demandStatusLabels[s] }));

  const regionalDirectionOptions = regionalDirectionList
    ? regionalDirectionList.map((rd) => ({ id: rd.code, name: rd.name }))
    : [];

  return (
    <Card>
      <CardHeader
        sx={{ cursor: 'pointer', padding: '5px 10px' }}
        onClick={toggleOpened}
        title="Critères"
        action={<CollapsableCardHeaderAction opened={opened} toggleOpened={toggleOpened} />}
      />
      <CardContent>
        <TextFieldAdapter
          placeholder="Rechercher un nom, une enseigne, un code pdv, une ville ou un numéro chrono..."
          name="fullText"
          label="Recherche libre"
        />
      </CardContent>
      <Collapse in={opened} data-testid="collapsable-card-content">
        <CardContent>
          <FormFiltersListener transformValues={formValuesToQueryString} />
          <Grid container rowSpacing={2}>
            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <TextFieldAdapter name="outletName" label="Enseigne" />
              </Grid>
              <Grid item xs={6}>
                <SelectAdapter
                  name="regionalDirectionCode"
                  label="Direction régionale"
                  options={regionalDirectionOptions}
                />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <DateRangePickerAdapter
                  lowerBoundFieldName="validationDateLowerBound"
                  upperBoundFieldName="validationDateUpperBound"
                  label="Date de validation"
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldAdapter name="departmentNumber" label="Numéro du département" />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <DateRangePickerAdapter
                  lowerBoundFieldName="receptionDateLowerBound"
                  upperBoundFieldName="receptionDateUpperBound"
                  label="Date de réception"
                />
              </Grid>
              <Grid item xs={6}>
                <SelectAdapter name="agencyCode" label="Zone de vente" options={agencyOptions} />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <TextFieldAdapter
                  name="demandYear"
                  label="Année de la demande"
                  validators={[onlyDigits, fixedLength(4)]}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldAdapter name="chronoNumber" label="No chrono" />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <SelectAdapter name="status" label="Statut" options={statusOptions} />
              </Grid>
              <Grid item xs={6}>
                <TextFieldAdapter name="candidateName" label="Nom candidat" />
              </Grid>
            </Grid>
            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <MinistryTerritorialAreaSelect fieldName="territorialArea" />
              </Grid>
              <Grid item xs={6}>
                <TextFieldAdapter name="ministryReference" label="Référence du ministère" />
              </Grid>
            </Grid>
            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <DateRangePickerAdapter
                  lowerBoundFieldName="verdictValidationDateLowerBound"
                  upperBoundFieldName="verdictValidationDateUpperBound"
                  label="Date de validation de l'avis"
                />
              </Grid>
              <Grid item xs={6}></Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const PmuDemandsFilters = ({ initialValues, formValuesToQueryString }: PmuDemandsFiltersProps) => {
  return (
    <Form initialValues={initialValues} validate={pmuDemandsFiltersValidator} subscription={{}} onSubmit={() => {}}>
      {() => (
        <PmuDemandsFiltersFields initialValues={initialValues} formValuesToQueryString={formValuesToQueryString} />
      )}
    </Form>
  );
};

export default PmuDemandsFilters;

import React from 'react';
import useUserFormHelper from '@eogile/agrements-common/src/user/details/hooks/useUserFormHelper';
import { PmuUser } from '../../pmuUserTypes';
import { PmuUserFormValues } from '../pmuUserFormTypes';
import { computeUnselectableProfiles, sortedProfileCodes, toApiSaveCommand, toFormValues } from '../pmuUserFormUtils';
import { Card, CardContent, Grid } from '@mui/material';
import UserCardHeader from '@eogile/agrements-common/src/user/details/components/UserCardHeader';
import { Form } from 'react-final-form';
import UserCommonFields from '@eogile/agrements-common/src/user/details/components/UserCommonFields';
import UserProfileCheckboxes from '@eogile/agrements-common/src/user/details/components/UserProfileCheckboxes';
import UserCardActions from '@eogile/agrements-common/src/user/details/components/UserCardActions';
import { pmuCivilityLabels } from '../../../utils/pmuLabelUtils';
import { pmuProfileDisplayLabels } from '../../pmuUserUtils';
import PmuUserAgencyField from './PmuUserAgencyField';
import PmuUserRegionalDirectField from './PmuUserRegionalDirectField';

const PmuUserForm = () => {
  const { initialValues, submitUserForm } = useUserFormHelper<PmuUser, PmuUserFormValues>({
    computeInitialValues: toFormValues,
    toApiUserSaveCommand: toApiSaveCommand,
  });

  const onSubmit = (values: PmuUserFormValues) => {
    return submitUserForm(values);
  };

  return (
    <Card>
      <UserCardHeader initialFormValues={initialValues} />
      <Form<PmuUserFormValues> initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Grid container columnSpacing={6}>
                <Grid container item rowSpacing={2} direction="column" xs={12} sm={6}>
                  <UserCommonFields civilityLabels={pmuCivilityLabels} />
                </Grid>
                <Grid container item rowSpacing={2} direction="column" xs={12} sm={6}>
                  <Grid item>
                    <UserProfileCheckboxes
                      profileCodes={sortedProfileCodes}
                      profileNames={pmuProfileDisplayLabels}
                      computeUnselectableProfiles={computeUnselectableProfiles}
                    />
                  </Grid>
                  <Grid item>
                    <PmuUserAgencyField />
                    <PmuUserRegionalDirectField />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <UserCardActions />
          </form>
        )}
      </Form>
    </Card>
  );
};

export default PmuUserForm;

import React, { useEffect } from 'react';
import AgencySelect from '@eogile/agrements-common/src/components/agencies/AgencySelect';
import { useField } from 'react-final-form';
import { PmuUserProfile } from '../../pmuUserTypes';
import { Agency } from '@eogile/agrements-common/src/common-types/directionTypes';

const computeAgencyName = (agency: Agency) => agency.name;

/**
 * Field representing the agency of a PMU user.
 *
 * The field is only displayed if "PMU sedentary business manager" profile is selected.
 */
const PmuUserAgencyField = () => {
  const {
    input: { value: profileCodes = [] },
  } = useField<PmuUserProfile[]>('profiles');
  const { input } = useField('agencyCode', { subscription: { value: true } });
  const shouldDisplayField = profileCodes.includes(PmuUserProfile.PMU_SEDENTARY_BUSINESS_MANAGER);

  useEffect(() => {
    if (!shouldDisplayField) {
      input.onChange(null);
    }
  }, [shouldDisplayField, input]);

  if (shouldDisplayField) {
    return <AgencySelect agencyName={computeAgencyName} disabled={false} fieldLabel="Zone de vente" required />;
  }
  return null;
};

export default PmuUserAgencyField;

import React from 'react';
import { useDataLoader } from '@eogile/agrements-common/src/hooks/useDataLoader';
import { Card, CardContent, CardHeader, Stack, Table, TableBody, TableHead, TableRow } from '@mui/material';
import TableCell from '@eogile/agrements-common/src/components/table/TableCell';
import ApiHelper, { pathWithQueryString } from '@eogile/agrements-common/src/api/apiHelper';
import SecondaryActionButton from '@eogile/agrements-common/src/components/buttons/SecondaryActionButton';
import { PmuAgency } from '../common-types/direction/pmuDirectionTypes';
import { UploadDirectionCsvButton } from '@eogile/agrements-common/src/components/direction/UploadDirectionCsvButton';

/**
 * Component in charge of displaying the list of Pmu agencies.
 */
export const PmuDirectionAdminPage = () => {
  const { data, setData } = useDataLoader<PmuAgency[]>('/api/agencies');

  const refresh = async () => {
    setData(await ApiHelper.get('/api/agencies'));
  };

  const actionButtons = (
    <Stack direction="row" spacing={2} alignItems="center">
      <UploadDirectionCsvButton refreshData={refresh} />
      <SecondaryActionButton href={pathWithQueryString('/api/agencies/export')} label="Exporter" />
    </Stack>
  );

  return (
    <Card>
      <CardHeader action={actionButtons} title="Consultation des zones de vente" />
      <CardContent>
        {data && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Direction Régionale</TableCell>
                <TableCell>Responsable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((agency) => (
                <TableRow key={agency.id}>
                  <TableCell>{agency.name}</TableCell>
                  <TableCell>{agency.regionalDirection.name}</TableCell>
                  <TableCell>{agency.managerName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

import React, { useEffect } from 'react';
import { useDataLoader } from '@eogile/agrements-common/src/hooks/useDataLoader';
import { useField } from 'react-final-form';
import { PmuUserProfile } from '../../pmuUserTypes';
import SelectAdapter from '@eogile/agrements-common/src/form/components/SelectAdapter';
import { RegionalDirection } from '@eogile/agrements-common/src/common-types/directionTypes';

const PmuUserRegionalDirectField = () => {
  const { data } = useDataLoader<RegionalDirection[]>('/api/regional-directions');
  const {
    input: { value: profileCodes = [] },
  } = useField<PmuUserProfile[]>('profiles');
  const { input } = useField('regionalDirectionId', { subscription: { value: true } });
  const shouldDisplayField = profileCodes.includes(PmuUserProfile.PMU_REGIONAL_DIRECTION_MANAGER);

  useEffect(() => {
    if (!shouldDisplayField) {
      input.onChange(null);
    }
  }, [shouldDisplayField, input]);

  if (shouldDisplayField) {
    return <SelectAdapter label="Direction régionale" name="regionalDirectionId" options={data ?? []} required />;
  }
  return null;
};

export default PmuUserRegionalDirectField;

import ApiHelper from '../api/apiHelper';
import { Agency } from '../common-types/directionTypes';
import { caseInsensitiveCompare } from './stringUtils';

export async function getAllAgenciesOrderedByName<T extends Agency = Agency>(): Promise<T[]> {
  const agencies: T[] = await ApiHelper.get('/api/agencies');
  agencies.sort((a1, a2) => caseInsensitiveCompare(a1.name, a2.name));
  return agencies;
}

export async function getAllAgenciesOrderedByCode<T extends Agency = Agency>(): Promise<T[]> {
  const agencies: T[] = await ApiHelper.get('/api/agencies');
  agencies.sort((a1, a2) => caseInsensitiveCompare(a1.code, a2.code));
  return agencies;
}

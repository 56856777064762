import { parseQueryString } from '@eogile/agrements-common/src/utils/queryStringUtils';
import {
  PmuDemandSearchCommand,
  PmuDemandsFiltersType,
  PmuDemandsLoadRequestParams,
  PmuDemandsSortableFields,
} from '../pmuDemandListTypes';
import { validateDateRangeFilter, validateDepartmentNumber } from '@eogile/agrements-common/src/demand/demandListUtils';
import {
  parseCommonDemandsListQueryString,
  toCommonDemandsListApiParams,
} from '@eogile/agrements-common/src/demand/demandsFiltersUtils';
import { DemandStatus } from '@eogile/agrements-common/src/demand/demandTypes';
import { CurrentPmuApplicationUserDetails } from '../../security/types';
import { isPmuUser } from '../../user/pmuUserUtils';
import { allPmuDemandStatuses } from '../pmuDemandUtils';
import { DEFAULT_DEMANDS_SORT_FIELD } from '@eogile/agrements-common/src/demand/demandListTypes';

export function parsePmuDemandsListQueryString(search: string): PmuDemandsLoadRequestParams {
  const params = parseQueryString(search);
  return {
    outletName: (params.outletName as string) ?? null,
    demandYear: params.demandYear ?? null,
    regionalDirectionCode: (params.regionalDirectionCode as string) ?? null,
    agencyCode: (params.agencyCode as string) ?? null,
    candidateName: (params.candidateName as string) ?? null,
    sortField: (params.sortField as PmuDemandsSortableFields) ?? DEFAULT_DEMANDS_SORT_FIELD,
    ...parseCommonDemandsListQueryString(search),
  };
}

export function toPmuDemandsListApiParams(
  params: PmuDemandsLoadRequestParams,
  userDetails: CurrentPmuApplicationUserDetails,
): PmuDemandSearchCommand {
  const buildStatuses = () => {
    if (isPmuUser(userDetails)) {
      return params.status ? [params.status] : [];
    } else {
      // ministry users cannot see CREATED demands
      return params.status ? [params.status] : allPmuDemandStatuses.filter((s) => s !== DemandStatus.CREATED);
    }
  };

  return {
    statuses: buildStatuses(),
    outletName: params.outletName,
    demandYear: params.demandYear ?? null,
    regionalDirectionCode: params.regionalDirectionCode,
    agencyCode: params.agencyCode,
    candidateName: params.candidateName,
    ...toCommonDemandsListApiParams(params),
  };
}

export const pmuDemandsFiltersValidator = (formValues: PmuDemandsFiltersType) => {
  return {
    departmentNumber: validateDepartmentNumber(formValues.departmentNumber),
    receptionDateUpperBound: validateDateRangeFilter(
      formValues.receptionDateLowerBound,
      formValues.receptionDateUpperBound,
    ),
    validationDateUpperBound: validateDateRangeFilter(
      formValues.validationDateLowerBound,
      formValues.validationDateUpperBound,
    ),
    verdictValidationDateUpperBound: validateDateRangeFilter(
      formValues.verdictValidationDateLowerBound,
      formValues.verdictValidationDateUpperBound,
    ),
  };
};

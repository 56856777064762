import { FormGridItem } from '../../../components/grid/FormGridItem';
import TextFieldAdapter from '../../../form/components/TextFieldAdapter';
import { withFieldNamePrefix } from '../../demandFormUtils';
import { FormGridContainer } from '../../../components/grid/FormGridContainer';
import { ZipCodeFieldAdapter } from '../../../form/components/ZipCodeFieldAdapter';
import { ReactNode } from 'react';

type Props = {
  infoTooltips?: {
    address1: ReactNode;
  };
};

export const DemandOutletAddress = ({ infoTooltips }: Props) => {
  const fieldName = withFieldNamePrefix('outlet.address');
  return (
    <FormGridContainer>
      <FormGridItem>
        <TextFieldAdapter
          name={fieldName('address1')}
          label="Adresse 1"
          required
          cssUppercase
          infoTooltipContent={infoTooltips?.address1}
        />
      </FormGridItem>
      <FormGridItem>
        <TextFieldAdapter name={fieldName('address2')} label="Adresse 2" cssUppercase />
      </FormGridItem>
      <FormGridItem>
        <ZipCodeFieldAdapter name={fieldName('zipCode')} label="Code postal" required />
      </FormGridItem>
      <FormGridItem>
        <TextFieldAdapter name={fieldName('city')} label="Ville" required cssUppercase />
      </FormGridItem>
    </FormGridContainer>
  );
};

import { Demand, VerdictType } from '@eogile/agrements-common/src/demand/demandTypes';
import { useStandardVerdictTypeFormHelper } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/useStandardVerdictTypeFormHelper';
import { VerdictTypeSelectAdapter } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/VerdictTypeSelectAdapter';
import { RejectionReasonSelectAdapter } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/RejectionReasonSelectAdapter';
import { pmuActiveRejectionReasons } from './pmuVerdictInputFormUtils';
import { useStandardRejectedVerdictFormHelper } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/useStandardRejectedVerdictFormHelper';
import { useStandardCancelledVerdictFormHelper } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/useStandardCancelledVerdictFormHelper';
import { CancellationCommentTextFieldAdapter } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/CancellationCommentTextFieldAdapter';
import { Grid } from '@mui/material';
import { hasAskedCancellation } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/verdictInputFormUtils';
import { VerdictInputCancellationAskedText } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/VerdictInputCancellationAskedText';

export const PmuVerdictInputFormContent = ({ demand }: { demand: Demand }) => {
  const { currentVerdictType, availableVerdictTypes } = useStandardVerdictTypeFormHelper(demand);
  const { activeRejectionReasons } = useStandardRejectedVerdictFormHelper(pmuActiveRejectionReasons);
  const { supportsMinistryCancellationFields } = useStandardCancelledVerdictFormHelper(demand);

  return (
    <Grid container spacing={2}>
      {hasAskedCancellation(demand) && (
        <Grid item xs={12}>
          <VerdictInputCancellationAskedText />
        </Grid>
      )}
      <Grid item xs={12}>
        <VerdictTypeSelectAdapter availableVerdictTypes={availableVerdictTypes} />
      </Grid>
      {currentVerdictType === VerdictType.REJECTED && (
        <Grid item xs={12}>
          <RejectionReasonSelectAdapter availableRejectionReasons={activeRejectionReasons} />
        </Grid>
      )}
      {currentVerdictType === VerdictType.CANCELLED && supportsMinistryCancellationFields && (
        <Grid item xs={12}>
          <CancellationCommentTextFieldAdapter label="Motif (texte libre)" />
        </Grid>
      )}
    </Grid>
  );
};

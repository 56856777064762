import {
  PmuCandidateType,
  PmuCaseType,
  PmuDemand,
  PmuFullDemand,
  PmuLightDemand,
  PmuNatureType,
} from './pmuDemandTypes';
import { DemandStatus } from '@eogile/agrements-common/src/demand/demandTypes';

export const pmuCaseTypeLabels: Record<PmuCaseType, string> = {
  [PmuCaseType.CREATION]: 'Création',
  [PmuCaseType.INCUMBENT_CHANGE]: 'Changement de titulaire',
  [PmuCaseType.LEGAL_REPRESENTATIVE_CHANGE]: 'Changement de représentant légal',
  [PmuCaseType.ASSOCIATE_CHANGE]: "Changement d'associé",
  [PmuCaseType.ASSOCIATE_ADDED]: "Ajout d'associé",
  [PmuCaseType.CASE_ADDITION]: 'Complément de dossier',
  [PmuCaseType.CASE_NEW_ATTEMPT]: 'Nouvelle présentation de dossier',
  [PmuCaseType.CASE_PREVIOUSLY_SUBMITTED]: 'Dossier déjà présenté',
};

export const pmuActiveCaseTypes: readonly PmuCaseType[] = Object.values(PmuCaseType).filter(
  (caseType) =>
    ![PmuCaseType.CASE_ADDITION, PmuCaseType.CASE_NEW_ATTEMPT, PmuCaseType.CASE_PREVIOUSLY_SUBMITTED].includes(
      caseType,
    ),
);

export const pmuNatureTypeLabels: Record<PmuNatureType, string> = {
  [PmuNatureType.NEW_CASE_PRESENTATION]: 'Nouvelle présentation de dossier',
  [PmuNatureType.CASE_ALREADY_PRESENTED]: 'Dossier déjà présenté',
  [PmuNatureType.CASE_COMPLEMENT]: 'Complément de dossier',
};

export const pmuCandidateTypeLabels: Record<PmuCandidateType, string> = {
  [PmuCandidateType.MALE_CANDIDATE]: 'Le Candidat',
  [PmuCandidateType.FEMALE_CANDIDATE]: 'La Candidate',
  [PmuCandidateType.MALE_MANAGER]: 'Le Gérant',
  [PmuCandidateType.FEMALE_MANAGER]: 'La Gérante',
  [PmuCandidateType.ASSOCIATE]: "L'Associé (e)",
  [PmuCandidateType.MALE_CO_MANAGER]: 'Le co-Gérant',
  [PmuCandidateType.FEMALE_CO_MANAGER]: 'La co-Gérante',
  [PmuCandidateType.MALE_PRESIDENT]: 'Le Président',
  [PmuCandidateType.FEMALE_PRESIDENT]: 'La Présidente',
};

export const isPmuLightDemand = (demand: PmuDemand): demand is PmuLightDemand => {
  return demand.receivedGrantedVerdictInPast12Months;
};

export const isPmuFullDemand = (demand: PmuDemand): demand is PmuFullDemand => {
  return !demand.receivedGrantedVerdictInPast12Months;
};

export const allPmuDemandStatuses = Object.values(DemandStatus).filter((s) => s !== DemandStatus.COMPLETE_CASE_VERDICT);

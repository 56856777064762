import { ListItemText } from '@mui/material';
import React from 'react';
import { useAppContext } from '@eogile/agrements-common/src/AppContext';
import { DirectionLabelUsage, ImportAgencyErrorItemProps, ImportAgencyErrorType } from './ImportAgencyTypes';

export const ImportAgencyErrorItem = ({ errorDetail }: ImportAgencyErrorItemProps) => {
  const { directionAdmin } = useAppContext();

  if (directionAdmin === false) {
    return null;
  }

  const getErrorMessage = (type: ImportAgencyErrorType) => {
    const directionInErrorTitle = directionAdmin.buildDirectionLabel(DirectionLabelUsage.ERROR_TITLE);
    const directionInErrorMsg = directionAdmin.buildDirectionLabel(DirectionLabelUsage.ERROR_MESSAGE);
    const cannotImportAgencyText = `Impossible d'importer ${directionInErrorTitle} ${errorDetail.entityName}`;
    const cannotDeleteAgencyText = `Impossible de supprimer ${directionInErrorTitle} ${errorDetail.entityName}`;
    const cannotDeleteRegionalDirectionText = `Impossible de supprimer la direction régionale ${errorDetail.entityName}`;

    const errorMessages: Record<ImportAgencyErrorType, string> = {
      [ImportAgencyErrorType.INVALID_COMMAND]: `${cannotImportAgencyText} : les informations sont invalides.`,
      [ImportAgencyErrorType.AGENCY_REFERENCED_BY_USER]: `${cannotDeleteAgencyText} : des utilisateurs sont associés à ${directionInErrorMsg}.`,
      [ImportAgencyErrorType.AGENCY_REFERENCED_BY_DEMAND]: `${cannotDeleteAgencyText} : des demandes ouvertes sont associées à ${directionInErrorMsg}.`,
      [ImportAgencyErrorType.REGIONAL_DIRECTION_REFERENCED_BY_USER]: `${cannotDeleteRegionalDirectionText} : des utilisateurs régionaux sont associés à cette direction régionale.`,
      [ImportAgencyErrorType.UNEXPECTED_ERROR]: `${cannotImportAgencyText} : une erreur inattendue s'est produite.`,
      [ImportAgencyErrorType.ABORTED_IMPORT]: `L'import a échoué. Veuillez vérifier que toutes les cellules de la colonne "code" contiennent des valeurs.`,
    };
    return errorMessages[type];
  };

  return <ListItemText disableTypography>{getErrorMessage(errorDetail.type)}</ListItemText>;
};

import { PmuUser, PmuUserProfile } from '../pmuUserTypes';
import { PmuUserApiSaveCommand, PmuUserFormValues } from './pmuUserFormTypes';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import { pmuProfileDisplayLabels } from '../pmuUserUtils';
import { caseInsensitiveCompare } from '@eogile/agrements-common/src/utils/stringUtils';

/**
 * Return the form values matching the given user.
 */
export function toFormValues(user?: PmuUser): PmuUserFormValues {
  if (!user) {
    return formValuesForNewUser();
  }
  return {
    id: user.id,
    login: user.login,
    firstName: user.firstName,
    lastName: user.lastName,
    civility: user.civility,
    email: user.email,
    profiles: [...user.profileCodes],
    agencyCode: user.agency?.code,
    regionalDirectionId: user.regionalDirection?.id,
  };
}

function formValuesForNewUser(): PmuUserFormValues {
  return {
    id: null,
    login: null,
    firstName: null,
    lastName: null,
    civility: null,
    email: null,
    profiles: [],
    agencyCode: null,
    regionalDirectionId: null,
  };
}

/**
 * Transform the valid form values to API request's payload.
 */
export function toApiSaveCommand(formValues: PmuUserFormValues): PmuUserApiSaveCommand {
  return {
    login: formValues.login!,
    company: UserCompany.PMU,
    lastName: formValues.lastName!,
    firstName: formValues.firstName!,
    civility: formValues.civility!,
    email: formValues.email!,
    profiles: [...formValues.profiles],
    agencyCode: formValues.agencyCode,
    regionalDirectionId: formValues.regionalDirectionId,
  };
}

/**
 * Return the PMU profiles that cannot be selected.
 *
 * Currently selected profiles are always present in the response.
 */
export function computeUnselectableProfiles(alreadySelectedProfiles: PmuUserProfile[]) {
  const profiles = [];
  if (alreadySelectedProfiles.includes(PmuUserProfile.PMU_SEDENTARY_BUSINESS_MANAGER)) {
    profiles.push(PmuUserProfile.PMU_REGIONAL_DIRECTION_MANAGER);
    profiles.push(PmuUserProfile.PMU_NATIONAL);
  }

  if (alreadySelectedProfiles.includes(PmuUserProfile.PMU_REGIONAL_DIRECTION_MANAGER)) {
    profiles.push(PmuUserProfile.PMU_SEDENTARY_BUSINESS_MANAGER);
    profiles.push(PmuUserProfile.PMU_NATIONAL);
  }

  if (alreadySelectedProfiles.includes(PmuUserProfile.PMU_NATIONAL)) {
    profiles.push(PmuUserProfile.PMU_SEDENTARY_BUSINESS_MANAGER);
    profiles.push(PmuUserProfile.PMU_REGIONAL_DIRECTION_MANAGER);
  }

  return profiles.filter((p) => !alreadySelectedProfiles.includes(p));
}

/**
 * The profile codes sorted by profile names.
 */
export const sortedProfileCodes = Object.values(PmuUserProfile).sort((p1, p2) => {
  return caseInsensitiveCompare(pmuProfileDisplayLabels[p1], pmuProfileDisplayLabels[p2]);
});

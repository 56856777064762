import { FieldRenderProps, useField } from 'react-final-form';
import { Dayjs } from 'dayjs';
import { today } from '@eogile/agrements-common/src/utils/dateUtils';
import { useEffect, useState } from 'react';

export const useExpectedOpeningDateFormHelper = () => {
  const expectedOpeningDateField: FieldRenderProps<Dayjs | undefined> = useField('expectedOpeningDate', {
    subscription: { value: true, dirty: true },
  });
  const dirty = expectedOpeningDateField.meta.dirty;
  const value = expectedOpeningDateField.input.value;
  const [showExpectedOpeningDateIsBeforeTodayWarning, setShowExpectedOpeningDateIsBeforeTodayWarning] = useState(false);

  useEffect(() => {
    // by default, Final-form transforms 'null/undefined' to '', this is why we need to check if value is truthy first
    // rather than directly using value?.isBefore(...)
    const showWarning = Boolean(dirty && value && value.isBefore(today()));
    setShowExpectedOpeningDateIsBeforeTodayWarning(showWarning);
  }, [dirty, value]);

  return {
    showExpectedOpeningDateIsBeforeTodayWarning,
  };
};

import { FormGridItem } from '../../../components/grid/FormGridItem';
import TextFieldAdapter from '../../../form/components/TextFieldAdapter';
import { DatePickerAdapter } from '../../../form/components/DatePickerAdapter';
import { withFieldNamePrefix } from '../../demandFormUtils';
import { noDigits } from '../../../form/helper/validators';
import { useIsLightDemandFormHelper } from '../../hooks/useIsLightDemandFormHelper';
import { postJanuaryFirst1900AndNotAfterTodayDatePickerProps } from '../../../form/helper/formUtils';

type Props = {
  /**
   * The Final-Form field name for this candidate.
   * Should be passed down from the parent DemandCandidateSection component.
   */
  name: string;
};

export const DemandCandidateCommonIdentityFields = ({ name }: Props) => {
  const isLightDemand = useIsLightDemandFormHelper();
  const fieldName = withFieldNamePrefix(name);

  return (
    <>
      <FormGridItem>
        <TextFieldAdapter name={fieldName('lastName')} label="Nom" required cssUppercase validators={[noDigits]} />
      </FormGridItem>
      {!isLightDemand && (
        <FormGridItem>
          <TextFieldAdapter
            name={fieldName('birthName')}
            label="Nom de naissance"
            cssUppercase
            validators={[noDigits]}
          />
        </FormGridItem>
      )}
      <FormGridItem>
        <TextFieldAdapter
          name={fieldName('firstNames')}
          label="Prénom d'usage"
          required
          cssCapitalize
          validators={[noDigits]}
        />
      </FormGridItem>
      <FormGridItem>
        <TextFieldAdapter name={fieldName('birthCity')} label="Ville de naissance" required cssUppercase />
      </FormGridItem>
      {!isLightDemand && (
        <FormGridItem>
          <TextFieldAdapter
            name={fieldName('birthDepartmentOrCountry')}
            label="N° Département ou Pays de naissance"
            required
            cssUppercase
          />
        </FormGridItem>
      )}
      <FormGridItem>
        <DatePickerAdapter
          name={fieldName('birthDate')}
          label="Date de naissance"
          required
          {...postJanuaryFirst1900AndNotAfterTodayDatePickerProps}
        />
      </FormGridItem>
    </>
  );
};

import React from 'react';
import PmuUsersFilters from './PmuUsersFilters';
import useUsersListHelper from '../hooks/useUsersListHelper';
import PmuUsersTable from './PmuUsersTable';

/**
 * Component in charge of displaying the list of PMU users with
 * its filters.
 */
const PmuUsersList = () => {
  const { filterValues, onPaginationOrSortChange, paginatedUsers, reloadUsers } = useUsersListHelper();
  return (
    <>
      <PmuUsersFilters filterValues={filterValues} />
      <PmuUsersTable
        command={filterValues}
        onCommandChange={onPaginationOrSortChange}
        paginatedUsers={paginatedUsers}
        reloadUsers={reloadUsers}
      />
    </>
  );
};

export default PmuUsersList;

import { Acquirer, Candidate, Demand, Outlet } from '@eogile/agrements-common/src/demand/demandTypes';
import { PmuAgency } from '../common-types/direction/pmuDirectionTypes';
import { LocalDate } from '@eogile/agrements-common/src/common-types/dateTypes';
import { MergeDeep } from 'type-fest';

export type PmuDemand = Demand & PmuDemandCommon & (PmuLightDemandSpecific | PmuFullDemandSpecific);
export type PmuLightDemand = Demand & PmuDemandCommon & PmuLightDemandSpecific;
export type PmuFullDemand = Demand & PmuDemandCommon & PmuFullDemandSpecific;

type PmuDemandCommon = Readonly<{
  agency: PmuAgency;
  caseType: PmuCaseType;
  referralDemand?: string;
  nature?: PmuNatureType;
  expectedOpeningDate: LocalDate;
}>;

type PmuLightDemandSpecific = Readonly<{
  receivedGrantedVerdictInPast12Months: true;
  previousChronoNumber: string;
  outlet: PmuLightOutlet;
  candidates: readonly PmuLightCandidate[];
}>;

type PmuFullDemandSpecific = Readonly<{
  receivedGrantedVerdictInPast12Months: false;
  outlet: PmuFullOutlet;
  candidates: readonly PmuFullCandidate[];
  acquirer: Acquirer;
}>;

export type PmuLightOutlet = Pick<Outlet, 'name' | 'address'>;

export type PmuFullOutlet = Readonly<
  MergeDeep<
    Outlet,
    {
      code?: string;
    }
  >
>;

export enum PmuCaseType {
  CREATION = 'CREATION',
  INCUMBENT_CHANGE = 'INCUMBENT_CHANGE',
  LEGAL_REPRESENTATIVE_CHANGE = 'LEGAL_REPRESENTATIVE_CHANGE',
  ASSOCIATE_CHANGE = 'ASSOCIATE_CHANGE',
  ASSOCIATE_ADDED = 'ASSOCIATE_ADDED',
  CASE_ADDITION = 'CASE_ADDITION',
  CASE_NEW_ATTEMPT = 'CASE_NEW_ATTEMPT',
  CASE_PREVIOUSLY_SUBMITTED = 'CASE_PREVIOUSLY_SUBMITTED',
}

export enum PmuNatureType {
  NEW_CASE_PRESENTATION = 'NEW_CASE_PRESENTATION',
  CASE_ALREADY_PRESENTED = 'CASE_ALREADY_PRESENTED',
  CASE_COMPLEMENT = 'CASE_COMPLEMENT',
}

export enum PmuCandidateType {
  MALE_CANDIDATE = 'MALE_CANDIDATE',
  FEMALE_CANDIDATE = 'FEMALE_CANDIDATE',
  MALE_MANAGER = 'MALE_MANAGER',
  FEMALE_MANAGER = 'FEMALE_MANAGER',
  ASSOCIATE = 'ASSOCIATE',
  MALE_CO_MANAGER = 'MALE_CO_MANAGER',
  FEMALE_CO_MANAGER = 'FEMALE_CO_MANAGER',
  MALE_PRESIDENT = 'MALE_PRESIDENT',
  FEMALE_PRESIDENT = 'FEMALE_PRESIDENT',
}

export type PmuFullCandidate = Candidate<PmuCandidateType>;
export type PmuLightCandidate = Pick<PmuFullCandidate, 'lastName' | 'firstNames' | 'birthCity' | 'birthDate'>;

export enum PmuAttachmentType {
  PieceIdentite = 'PieceIdentite',
  AvisImposition = 'AvisImposition',
  LivretFamille = 'LivretFamille',
  ActeAchat = 'ActeAchat',
  Bail = 'Bail',
  PromesseVente = 'PromesseVente',
  Adjudication = 'Adjudication',
  ContratLocationGerance = 'ContratLocationGerance',
  ConventionOccupation = 'ConventionOccupation',
  AccordPret = 'AccordPret',
  AccordPretBrasseur = 'AccordPretBrasseur',
  JustificationApports = 'JustificationApports',
  ExtraitK = 'ExtraitK',
  ExtraitKbis = 'ExtraitKbis',
  StatutsSociete = 'StatutsSociete',
  LiasseFiscale = 'LiasseFiscale',
  CessionParts = 'CessionParts',
  NonChangementOrganesDirection = 'NonChangementOrganesDirection',
  JustificationFondsFinancementLocationGerance = 'JustificationFondsFinancementLocationGerance',
  AutresPieces = 'AutresPieces',
  PlanCadastral = 'PlanCadastral',
  AttestationZoneProtegee = 'AttestationZoneProtegee',
  PlanDeFinancement = 'PlanDeFinancement',
  AttestationDemandeur = 'AttestationDemandeur',
}

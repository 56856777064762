import { useIsLightDemandFormHelper } from '@eogile/agrements-common/src/demand/hooks/useIsLightDemandFormHelper';
import { SectionCard } from '@eogile/agrements-common/src/components/cards/SectionCard';
import { DemandCurrentStatus } from '@eogile/agrements-common/src/demand/components/DemandCurrentStatus';
import { FormGridItem } from '@eogile/agrements-common/src/components/grid/FormGridItem';
import ReadOnlyField from '@eogile/agrements-common/src/form/components/ReadOnlyField';
import { pmuCaseTypeLabels, pmuNatureTypeLabels } from '../pmuDemandUtils';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import { DatePickerAdapter } from '@eogile/agrements-common/src/form/components/DatePickerAdapter';
import CheckboxAdapter from '@eogile/agrements-common/src/form/components/CheckboxAdapter';
import { PmuDemand, PmuNatureType } from '../pmuDemandTypes';
import { FormMode, useFormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import { EnumSelectAdapter } from '@eogile/agrements-common/src/form/components/EnumSelectAdapter';
import { generalInformationSectionTitle } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { FormGridContainer } from '@eogile/agrements-common/src/components/grid/FormGridContainer';
import { FormGridContainersStack } from '@eogile/agrements-common/src/components/grid/FormGridContainersStack';
import { useExpectedOpeningDateFormHelper } from '../hooks/useExpectedOpeningDateFormHelper';
import { usePmuAgencyOptionsFormHelper } from '../hooks/usePmuAgencyOptionsFormHelper';
import SelectAdapter from '@eogile/agrements-common/src/form/components/SelectAdapter';
import { ReferralDemandFormGridContainer } from '@eogile/agrements-common/src/demand/components/ReferralDemandFormGridContainer';
import { computeCaseTypes } from '../pmuDemandFormUtils';
import { useExistingAttachmentsRemovalWarningDisplayHelper } from '@eogile/agrements-common/src/demand/components/ExistingAttachmentsRemovalWarningFieldsProvider';

type Props = {
  demand: PmuDemand | undefined;
};

export const PmuDemandGeneralInformationSection = ({ demand }: Props) => {
  const isLightDemand = useIsLightDemandFormHelper();
  const mode = useFormMode();
  const { agencyOptions } = usePmuAgencyOptionsFormHelper(demand);
  const { showExpectedOpeningDateIsBeforeTodayWarning } = useExpectedOpeningDateFormHelper();
  const { getExistingAttachmentsRemovalWarningIfNeeded } = useExistingAttachmentsRemovalWarningDisplayHelper();

  return (
    <SectionCard title={generalInformationSectionTitle}>
      <FormGridContainersStack>
        <DemandCurrentStatus demand={demand} showDemandCompany={false} />
        <FormGridContainer>
          {mode === FormMode.READ && (
            <FormGridItem>
              <ReadOnlyField label="Direction régionale" value={demand!.agency.regionalDirection.name} />
            </FormGridItem>
          )}
          <FormGridItem>
            <SelectAdapter
              name="agencyCode"
              label="Zone de vente"
              options={agencyOptions}
              required
              disabled={agencyOptions.length === 1}
            />
          </FormGridItem>
        </FormGridContainer>
        <FormGridContainer>
          <FormGridItem>
            <EnumSelectAdapter
              name="caseType"
              label="Type de dossier"
              required
              options={computeCaseTypes(mode)}
              optionName={(opt) => pmuCaseTypeLabels[opt]}
              warningText={getExistingAttachmentsRemovalWarningIfNeeded('caseTypeChange')}
            />
          </FormGridItem>
        </FormGridContainer>
        <ReferralDemandFormGridContainer />
        <FormGridContainer>
          <FormGridItem>
            <EnumSelectAdapter
              name="nature"
              label="Nature"
              options={Object.values(PmuNatureType)}
              optionName={(opt) => pmuNatureTypeLabels[opt]}
            />
          </FormGridItem>
          <FormGridItem>
            <DatePickerAdapter
              label="Date d'ouverture prévisionnelle"
              name="expectedOpeningDate"
              required
              warningText={
                showExpectedOpeningDateIsBeforeTodayWarning
                  ? "Attention, la date sélectionnée est antérieure à aujourd'hui"
                  : undefined
              }
            />
          </FormGridItem>
          <FormGridItem>
            <CheckboxAdapter
              label="Y-a-t-il eu un avis favorable SCCJ pour ce détaillant dans les 12 derniers mois ?"
              name="receivedGrantedVerdictInPast12Months"
            />
          </FormGridItem>
          {isLightDemand && (
            <FormGridItem>
              <TextFieldAdapter name="previousChronoNumber" label="N° Chrono ancienne demande" />
            </FormGridItem>
          )}
        </FormGridContainer>
      </FormGridContainersStack>
    </SectionCard>
  );
};

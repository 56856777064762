import { PmuUsersApiSearchCommand, PmuUsersFilterValues, PmuUsersSortableFields } from './pmuUsersTypes';
import { convertWithDefaultNumber, parseQueryString } from '@eogile/agrements-common/src/utils/queryStringUtils';
import { PmuUserProfile } from '../pmuUserTypes';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, SortOrder } from '@eogile/agrements-common/src/components/table/tableTypes';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';

/**
 * Transform the given query string into valid filter form values.
 */
export function queryStringToFilterValues(search: string): PmuUsersFilterValues {
  const params = parseQueryString(search);
  return {
    login: (params.login as string) ?? null,
    lastName: (params.lastName as string) ?? null,
    email: (params.email as string) ?? null,
    agencyCode: (params.agencyCode as string) ?? null,
    profile: (params.profile as PmuUserProfile) ?? null,
    page: convertWithDefaultNumber(params.page, DEFAULT_PAGE),
    pageSize: convertWithDefaultNumber(params.pageSize, DEFAULT_PAGE_SIZE),
    sortField: (params.sortField as PmuUsersSortableFields) ?? 'LOGIN',
    sortOrder: (params.sortOrder as SortOrder) ?? 'ASC',
  };
}

/**
 * Transform th given filter values into a search command that can
 * be understood by the API.
 */
export function toApiSearchCommand(filterValues: PmuUsersFilterValues): PmuUsersApiSearchCommand {
  return {
    ...filterValues,
    company: UserCompany.PMU,
  };
}

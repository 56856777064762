import { usePmuDemandFormChangesHelper } from '../hooks/usePmuDemandFormChangesHelper';

/**
 * dummy component whose role is to enable the hooks that handle the interactions between field values changes.
 *
 * We could use react-final-form-listeners "OnChange" component to achieve a similar behavior,
 * however as of March 16th 2023, this library still hasn't updated its React peer dependency to React 18+,
 * so we'd have to include additional flags to the "npm install" command if we want to use this dependency
 * (related PR: https://github.com/final-form/react-final-form-listeners/pull/45)
 */
export const PmuDemandFormOnChanges = () => {
  usePmuDemandFormChangesHelper();

  return <></>;
};

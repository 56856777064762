import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import SortableTableCell from '@eogile/agrements-common/src/components/table/SortableTableCell';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { PmuDemandsSortableFields } from '../pmuDemandListTypes';

type DemandsTableHeaderProps = {
  onCommandChange: (newCommand: PaginationAndSortCommand<PmuDemandsSortableFields>) => void;
  command: PaginationAndSortCommand<PmuDemandsSortableFields>;
};

const PmuDemandsTableHeader = ({ onCommandChange, command }: DemandsTableHeaderProps) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableCell<PmuDemandsSortableFields>
          label="No Chrono"
          sortField="CHRONO_NUMBER"
          onChange={onCommandChange}
          command={command}
        />
        <SortableTableCell<PmuDemandsSortableFields>
          label="Nom de l'enseigne"
          sortField="OUTLET_NAME"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<PmuDemandsSortableFields>
          label="Ville de l'enseigne"
          sortField="OUTLET_CITY"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<PmuDemandsSortableFields>
          label="Direction régionale"
          sortField="AGENCY_REGIONAL_DIRECTION"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<PmuDemandsSortableFields>
          label="Zone de vente"
          sortField="AGENCY"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<PmuDemandsSortableFields>
          label="Date de validation"
          sortField="VALIDATION_DATE"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<PmuDemandsSortableFields>
          label="Date de réception"
          sortField="RECEPTION_DATE"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<PmuDemandsSortableFields>
          label="Statut"
          sortField="STATUS"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<PmuDemandsSortableFields>
          label="Date d'avis"
          sortField="AVIS_DATE"
          onChange={onCommandChange}
          command={command}
        />
      </TableRow>
    </TableHead>
  );
};

export default PmuDemandsTableHeader;

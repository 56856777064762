import { ChangeEvent, useState } from 'react';
import { MAX_UPLOAD_FILE_SIZE } from '../../demand/attachmentUtils';
import { Severity } from '../../utils/severityUtils';
import ApiHelper from '../../api/apiHelper';
import { captureException } from '@sentry/react';
import useSnackbar from '../../snackbar/useSnackbar';
import MainActionButton from '../buttons/MainActionButton';
import { ImportAgencyResultDialog } from './ImportAgencyResultDialog';
import { ImportResult } from './ImportAgencyTypes';

type Props = { refreshData: () => void };

export const UploadDirectionCsvButton = ({ refreshData }: Props) => {
  const [pendingUpload, setPendingUpload] = useState(false);
  const [importResult, setImportResult] = useState<ImportResult | null>(null);
  const [dialogOpened, setDialogOpened] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const onCloseDialog = () => {
    setDialogOpened(false);
    refreshData();
  };

  const onImportFinish = (importResult: ImportResult) => {
    setImportResult(importResult);
    setDialogOpened(true);
    if (resultsContainOnlyErrors(importResult)) {
      displayErrorMessage();
    } else {
      enqueueSnackbar({
        severity: Severity.SUCCESS,
        content: 'Les données ont bien été enregistrées.',
      });
    }
  };

  const displayErrorMessage = () => {
    enqueueSnackbar({
      severity: Severity.ERROR,
      content: "Les données n'ont pas pu être enregistrées.",
    });
  };

  const resultsContainOnlyErrors = (importResult: ImportResult) => {
    return (
      importResult.nbCreated === 0 &&
      importResult.nbDeleted === 0 &&
      importResult.nbUpdated === 0 &&
      importResult.errors.length > 0
    );
  };

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file.size > MAX_UPLOAD_FILE_SIZE) {
      enqueueSnackbar({
        severity: Severity.ERROR,
        content: 'Le fichier dépasse la taille maximale de fichier autorisée (50 Mo).',
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    try {
      setPendingUpload(true);
      const importResult = await ApiHelper.post(`/api/agencies/import`, formData);
      onImportFinish(importResult);
    } catch (e) {
      displayErrorMessage();
      captureException(e);
    } finally {
      setPendingUpload(false);
    }
  };

  return (
    <>
      <ImportAgencyResultDialog importResult={importResult} opened={dialogOpened} onClose={onCloseDialog} />
      <MainActionButton
        type="upload"
        label="Importer"
        pendingSpinner={pendingUpload}
        fileInput={<input hidden accept={'.csv'} type="file" onChange={handleUpload} />}
      />
    </>
  );
};

import { AcquirerType } from '../demandTypes';
import { useFormState } from 'react-final-form';
import { AcquirerFormValues } from '../demandFormTypes';
import { useAcquirerTypeFormValue } from './useAcquirerTypeFormValue';

/**
 * @param supportsEirlFieldForNewDemands if <code>true</code>, then the form should display the eirl checkbox
 * if <code>acquirerType === IN_OWN_NAME</code>. Otherwise, this checkbox should only be displayed when the initial
 * value of the eirl field is <code>true</code>
 */
export const useAcquirerFormHelper = (supportsEirlFieldForNewDemands: boolean) => {
  const acquirerTypeValue = useAcquirerTypeFormValue();
  const { initialValues } = useFormState<{ acquirer: AcquirerFormValues }>();
  const initialEirlValue = Boolean(
    initialValues?.acquirer?.type === AcquirerType.IN_OWN_NAME && initialValues.acquirer.eirl != null,
  );

  return {
    acquirerType: acquirerTypeValue,
    supportsEirlField: initialEirlValue || supportsEirlFieldForNewDemands,
  };
};

import { useIsLightDemandFormHelper } from '@eogile/agrements-common/src/demand/hooks/useIsLightDemandFormHelper';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { withFieldNamePrefix } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { useFieldArray } from 'react-final-form-arrays';
import { useEirlFormValue } from './useEirlFormValue';
import { useAcquirerTypeFormValue } from '@eogile/agrements-common/src/demand/hooks/useAcquirerTypeFormValue';
import { PmuCandidateType } from '../pmuDemandTypes';
import { pmuCandidateTypesCompatibleWith } from '../pmuDemandFormUtils';
import { AcquirerType } from '@eogile/agrements-common/src/demand/demandTypes';

export const usePmuDemandFormChangesHelper = () => {
  useLightDemandChangeFormHelper();
  useEirlChangeFormHelper();
  useAcquirerTypeChangeFormHelper();
};

const useLightDemandChangeFormHelper = () => {
  const { change } = useForm();
  const isLightDemand = useIsLightDemandFormHelper();
  const fieldArray = useFieldArray('candidates', { subscription: { length: true } });

  useEffect(() => {
    if (isLightDemand) {
      const clearOutlet = () => {
        const fieldName = withFieldNamePrefix('outlet');
        change(fieldName('code'), undefined);
        change(fieldName('operatingMode'), undefined);
        change(fieldName('acquisitionDate'), undefined);
        change(fieldName('phoneNumbers'), undefined);
      };
      const clearCandidate = (name: string) => {
        const fieldName = withFieldNamePrefix(name);
        change(fieldName('type'), undefined);
        change(fieldName('civility'), undefined);
        change(fieldName('birthName'), undefined);
        change(fieldName('birthDepartmentOrCountry'), undefined);
        change(fieldName('email'), undefined);
        change(fieldName('address'), undefined);
        change(fieldName('phoneNumbers'), undefined);
      };
      change('acquirer', undefined);
      clearOutlet();
      fieldArray.fields.forEach(clearCandidate);
    } else {
      change('previousChronoNumber', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLightDemand]);
};

const useEirlChangeFormHelper = () => {
  const { mutators } = useForm();
  const { fields } = useFieldArray('candidates', { subscription: { length: true } });
  const eirlValue = useEirlFormValue();

  useEffect(() => {
    if (eirlValue) {
      const indexesToRemove = fields.map((name, index) => index).filter((index) => index > 0);
      mutators.removeBatch('candidates', indexesToRemove);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eirlValue]);
};

const useAcquirerTypeChangeFormHelper = () => {
  const { change, getFieldState } = useForm();
  const acquirerType = useAcquirerTypeFormValue();
  const { fields } = useFieldArray('candidates', { subscription: { length: true } });

  useEffect(() => {
    const clearEirl = () => {
      change('acquirer.eirl', undefined);
    };
    const clearCompanyType = () => {
      change('acquirer.companyType', undefined);
    };
    if (!acquirerType) {
      clearEirl();
      clearCompanyType();
    } else if (acquirerType === AcquirerType.COMPANY) {
      clearEirl();
    } else {
      clearCompanyType();
    }

    fields.forEach((name) => {
      const candidateTypeFieldName = `${name}.type`;
      const state = getFieldState(candidateTypeFieldName);
      const currentCandidateType = state?.value as PmuCandidateType | undefined;
      if (currentCandidateType && !pmuCandidateTypesCompatibleWith(acquirerType).includes(currentCandidateType)) {
        change(candidateTypeFieldName, undefined);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acquirerType]);
};

import { FormGridItem } from '../../../components/grid/FormGridItem';
import TextFieldAdapter from '../../../form/components/TextFieldAdapter';
import { withFieldNamePrefix } from '../../demandFormUtils';
import { FormGridContainer } from '../../../components/grid/FormGridContainer';
import { ZipCodeFieldAdapter } from '../../../form/components/ZipCodeFieldAdapter';
import { noDigits } from '../../../form/helper/validators';

type Props = {
  /**
   * The Final-Form field name for this candidate.
   * Should be passed down from the parent DemandCandidateSection component.
   */
  name: string;
};

export const DemandCandidateAddress = ({ name }: Props) => {
  const fieldName = withFieldNamePrefix(`${name}.address`);

  return (
    <FormGridContainer>
      <FormGridItem>
        <TextFieldAdapter name={fieldName('address1')} label="Adresse 1" required cssUppercase />
      </FormGridItem>
      <FormGridItem>
        <TextFieldAdapter name={fieldName('address2')} label="Adresse 2" cssUppercase />
      </FormGridItem>
      <FormGridItem>
        <ZipCodeFieldAdapter name={fieldName('zipCode')} label="Code postal" required />
      </FormGridItem>
      <FormGridItem>
        <TextFieldAdapter name={fieldName('city')} label="Ville" required cssUppercase validators={[noDigits]} />
      </FormGridItem>
      <FormGridItem>
        <TextFieldAdapter name={fieldName('country')} label="Pays si différent de France" validators={[noDigits]} />
      </FormGridItem>
    </FormGridContainer>
  );
};

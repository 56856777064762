import { FormGridItem } from '../../../components/grid/FormGridItem';
import TextFieldAdapter from '../../../form/components/TextFieldAdapter';
import { withFieldNamePrefix } from '../../demandFormUtils';
import { FormGridContainer } from '../../../components/grid/FormGridContainer';
import { PhoneFieldAdapter } from '../../../form/components/PhoneFieldAdapter';
import { email } from '../../../form/helper/validators';

type Props = {
  /**
   * The Final-Form field name for this candidate.
   * Should be passed down from the parent DemandCandidateSection component.
   */
  name: string;
};

export const DemandCandidateContactFields = ({ name }: Props) => {
  const fieldName = withFieldNamePrefix(name);

  return (
    <FormGridContainer>
      <FormGridItem>
        <PhoneFieldAdapter name={fieldName('phoneNumbers.phone1')} label="Téléphone 1" required />
      </FormGridItem>
      <FormGridItem>
        <PhoneFieldAdapter name={fieldName('phoneNumbers.phone2')} label="Téléphone 2" />
      </FormGridItem>
      <FormGridItem>
        <TextFieldAdapter name={fieldName('email')} label="Email" validators={[email]} />
      </FormGridItem>
    </FormGridContainer>
  );
};

import { useLocation, useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import { parsePmuDemandsListQueryString, toPmuDemandsListApiParams } from './filters/pmuDemandsFiltersUtils';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { toQueryString } from '@eogile/agrements-common/src/utils/queryStringUtils';
import { useDataLoader } from '@eogile/agrements-common/src/hooks/useDataLoader';
import { PaginatedListResponse } from '@eogile/agrements-common/src/api/apiTypes';
import PmuDemandsFilters from './filters/PmuDemandsFilters';
import {
  extractFormValuesFromLoadRequestParams,
  extractPaginationAndSortCommand,
  formValuesToQueryString,
  mergePaginationAndSortCommandWithParams,
} from '@eogile/agrements-common/src/utils/tableUtils';
import { PmuDemandsLoadRequestParams, PmuDemandsSortableFields, PmuSimpleDemandView } from './pmuDemandListTypes';
import PmuDemandsTableCard from './table/PmuDemandsTableCard';
import { usePmuAuthenticatedCurrentUser } from '../security/hooks';

export const PmuDemandsList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails } = usePmuAuthenticatedCurrentUser();

  const requestParams: PmuDemandsLoadRequestParams = useMemo(() => {
    return parsePmuDemandsListQueryString(location.search);
  }, [location.search]);

  const onPaginationOrSortChange = (newCommand: PaginationAndSortCommand<PmuDemandsSortableFields>) => {
    const newSearch = toQueryString(mergePaginationAndSortCommandWithParams(requestParams, newCommand));
    navigate({ search: newSearch }, { replace: false });
  };

  const apiParams = useMemo(() => toPmuDemandsListApiParams(requestParams, userDetails), [requestParams, userDetails]);

  const { data } = useDataLoader<PaginatedListResponse<PmuSimpleDemandView>>('/api/demands', apiParams);

  const filterValues = extractFormValuesFromLoadRequestParams(requestParams);

  return (
    <>
      <PmuDemandsFilters
        initialValues={filterValues}
        formValuesToQueryString={formValuesToQueryString(requestParams)}
      />
      {data && (
        <PmuDemandsTableCard
          apiParams={apiParams}
          data={data}
          command={extractPaginationAndSortCommand(requestParams)}
          onCommandChange={onPaginationOrSortChange}
        />
      )}
    </>
  );
};

import { FieldArray } from 'react-final-form-arrays';
import { PmuDemandCandidateSection } from './PmuDemandCandidateSection';
import { FormMode, useFormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import MainActionButton from '@eogile/agrements-common/src/components/buttons/MainActionButton';
import React from 'react';
import { useEirlFormValue } from '../hooks/useEirlFormValue';
import { PmuDemand } from '../pmuDemandTypes';

type PmuDemandCandidatesArrayProps = {
  demand: PmuDemand | undefined;
};

export const PmuDemandCandidatesArray = ({ demand }: PmuDemandCandidatesArrayProps) => {
  const mode = useFormMode();
  const eirlValue = useEirlFormValue();

  return (
    <FieldArray name="candidates">
      {({ fields }) => (
        <>
          {fields.map((name, index) => (
            <PmuDemandCandidateSection key={name} name={name} index={index} demand={demand} />
          ))}
          {mode !== FormMode.READ && !eirlValue && (
            <MainActionButton
              sx={{ mt: '20px' }}
              action={() => fields.push(undefined)}
              label="Ajouter un candidat"
              dataTestId="add-candidate-btn"
            />
          )}
        </>
      )}
    </FieldArray>
  );
};

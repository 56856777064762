import { createContext, ReactNode, useContext, useMemo } from 'react';
import { ExistingAttachmentsRemovalWarningFields } from '../demandFormTypes';
import { AcquirerType, Demand, OperatingMode } from '../demandTypes';
import { FormMode, useFormMode } from '../../form/helper/FormModeProvider';
import { useFormState } from 'react-final-form';
import { Dayjs } from 'dayjs';
import { existingAttachmentRemovalWarningText } from '../demandFormUtils';

type ExistingAttachmentsRemovalWarningDisplayHelper = {
  getExistingAttachmentsRemovalWarningIfNeeded: (
    type: keyof ExistingAttachmentsRemovalWarningFields,
  ) => string | undefined;
};

const Ctx = createContext<ExistingAttachmentsRemovalWarningFields | null>(null);

export const ExistingAttachmentsRemovalWarningFieldsProvider = ({
  demand,
  children,
}: {
  demand: Demand | undefined;
  children: ReactNode;
}) => {
  const value = useExistingAttachmentsRemovalWarningFields(demand);

  return <Ctx.Provider value={value}>{children}</Ctx.Provider>;
};

export const useExistingAttachmentsRemovalWarningDisplayHelper = (): ExistingAttachmentsRemovalWarningDisplayHelper => {
  const value = useContext(Ctx)! as ExistingAttachmentsRemovalWarningFields;

  const getExistingAttachmentsRemovalWarningIfNeeded = (type: keyof ExistingAttachmentsRemovalWarningFields) => {
    if (value[type]) {
      return existingAttachmentRemovalWarningText;
    }
    return undefined;
  };

  return {
    getExistingAttachmentsRemovalWarningIfNeeded,
  };
};

type CommonFormValues = {
  caseType?: string;
  outlet?: {
    operatingMode?: OperatingMode;
    acquisitionDate?: Dayjs;
  };
  acquirer?: {
    type?: AcquirerType;
  };
};

function useExistingAttachmentsRemovalWarningFields(
  demand: Demand | undefined,
): ExistingAttachmentsRemovalWarningFields {
  const mode = useFormMode();
  const { values, initialValues } = useFormState<CommonFormValues>({
    subscription: { initialValues: true, values: true },
  });

  return useMemo(() => {
    if (mode !== FormMode.EDIT || !demand || demand.attachments.length === 0) {
      return {
        caseTypeChange: false,
        acquirerTypeChange: false,
        operatingModeChange: false,
        acquisitionDateChange: false,
      };
    }

    const initialFieldValues = {
      caseType: initialValues.caseType,
      acquirerType: initialValues.acquirer?.type,
      operatingMode: initialValues.outlet?.operatingMode,
      acquisitionDate: initialValues.outlet?.acquisitionDate,
    };

    const areDifferentDates = (d1: Dayjs | undefined, d2: Dayjs | undefined) => {
      if (d1 && d2) {
        return !d1.isSame(d2, 'date');
      }
      return d1 !== d2;
    };

    return {
      caseTypeChange: initialFieldValues.caseType !== values.caseType,
      acquirerTypeChange: initialFieldValues.acquirerType !== values.acquirer?.type,
      operatingModeChange: initialFieldValues.operatingMode !== values.outlet?.operatingMode,
      acquisitionDateChange: areDifferentDates(initialFieldValues.acquisitionDate, values.outlet?.acquisitionDate),
    };
  }, [mode, demand, values, initialValues]);
}

import React, { useMemo } from 'react';
import { useDataLoader } from '../../hooks/useDataLoader';
import { Agency } from '../../common-types/directionTypes';
import SelectAdapter from '../../form/components/SelectAdapter';
import { caseInsensitiveCompare } from '../../utils/stringUtils';

type Props = {
  agencyName: (agency: Agency) => string;
  disabled: boolean;
  fieldLabel: string;
  required?: boolean;
};

/**
 * The hook is inlined in the component module because it
 * should not be used outside of it.
 */
const useAgencyOptions = (agencyName: (agency: Agency) => string) => {
  const { data } = useDataLoader<Agency[]>('/api/agencies');
  return useMemo(
    () =>
      (data ?? [])
        .map((agency) => ({
          id: agency.code,
          name: agencyName(agency),
        }))
        .sort((a1, a2) => caseInsensitiveCompare(a1.name, a2.name)),
    [data, agencyName],
  );
};

/**
 * Component that loads agencies from the server and displays a "select"
 * component to pick one of them.
 */
const AgencySelect = ({ agencyName, disabled, fieldLabel, required = false }: Props) => {
  const agencyOptions = useAgencyOptions(agencyName);
  return (
    <SelectAdapter
      disabled={disabled}
      name="agencyCode"
      label={fieldLabel}
      options={agencyOptions}
      required={required}
    />
  );
};

export default AgencySelect;

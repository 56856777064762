import { VerdictInputDialogProps } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/verdictInputComponentTypes';
import { PmuDemand } from '../../../pmuDemandTypes';
import { useMemo } from 'react';
import { buildInitialPmuVerdictInputFormValues, buildPmuVerdictInputCommand } from './pmuVerdictInputFormUtils';
import { PmuVerdictInputFormContent } from './PmuVerdictInputFormContent';
import { VerdictInputDialogWrapper } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/VerdictInputDialogWrapper';

export const PmuVerdictInputDialog = ({
  demand,
  open,
  onClose,
  onSubmitSuccess,
}: VerdictInputDialogProps<PmuDemand>) => {
  const initialFormValues = useMemo(() => buildInitialPmuVerdictInputFormValues(demand), [demand]);

  return (
    <VerdictInputDialogWrapper
      demand={demand}
      open={open}
      onClose={onClose}
      initialFormValues={initialFormValues}
      formValuesToCommand={buildPmuVerdictInputCommand}
      onSubmitSuccess={onSubmitSuccess}
    >
      <PmuVerdictInputFormContent demand={demand} />
    </VerdictInputDialogWrapper>
  );
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { PmuContext } from './PmuContext';
import AppRoot from '@eogile/agrements-common/src/AppRoot';
import baseTheme from '@eogile/agrements-common/src/baseTheme';
import { initializeSentry } from '@eogile/agrements-common/src/config/sentry/sentryInitializer';

// noinspection JSIgnoredPromiseFromCall
initializeSentry();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppRoot appContext={PmuContext} theme={baseTheme} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { usePmuAuthenticatedCurrentUser } from '../../security/hooks';
import { Option } from '@eogile/agrements-common/src/form/components/SelectAdapter';
import { useCallback } from 'react';
import { PmuAgency } from '../../common-types/direction/pmuDirectionTypes';
import { getAllAgenciesOrderedByName } from '@eogile/agrements-common/src/utils/agencyUtils';
import { FormMode, useFormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import { PmuDemand } from '../pmuDemandTypes';
import { usePromiseLoader } from '@eogile/agrements-common/src/hooks/usePromiseLoader';
import { currentUserAgency } from '../../user/pmuUserUtils';

export const usePmuAgencyOptionsFormHelper = (existingDemand: PmuDemand | undefined) => {
  const formMode = useFormMode();
  const { userDetails } = usePmuAuthenticatedCurrentUser();
  const agencyOptionsLoader: () => Promise<Option<string>[]> = useCallback(async () => {
    let agencies: PmuAgency[];
    if (formMode === FormMode.READ) {
      agencies = [existingDemand!.agency];
    } else if (Boolean(currentUserAgency(userDetails))) {
      agencies = [currentUserAgency(userDetails)!];
    } else {
      agencies = await getAllAgenciesOrderedByName();
    }
    return agencies.map((agency) => ({ id: agency.code, name: agency.name }));
  }, [formMode, existingDemand, userDetails]);
  const { data: agencyOptions } = usePromiseLoader(agencyOptionsLoader);
  return { agencyOptions: agencyOptions ?? [] };
};

import { User } from '@eogile/agrements-common/src/user/usersTypes';

export type PmuUser = User &
  Readonly<{
    profileCodes: readonly PmuUserProfile[];
    agency: AgencySummary;
    regionalDirection: RegionalDirectionSummary;
  }>;

export enum PmuUserProfile {
  PMU_ADMIN = 'PMU_ADMIN',
  PMU_NATIONAL = 'PMU_NATIONAL',
  PMU_REGIONAL_DIRECTION_MANAGER = 'PMU_REGIONAL_DIRECTION_MANAGER',
  PMU_SEDENTARY_BUSINESS_MANAGER = 'PMU_SEDENTARY_BUSINESS_MANAGER',
}

export type AgencySummary = Readonly<{
  code: string;
  name: string;
}>;

export type RegionalDirectionSummary = Readonly<{
  id: string;
  name: string;
}>;

import { FormControl, FormControlLabel, FormHelperText, InputLabel, Radio, RadioGroup } from '@mui/material';
import { isDisabledOrReadFormMode } from '../helper/formUtils';
import { useField } from 'react-final-form';
import { useFormMode } from '../helper/FormModeProvider';
import { composeValidators, FieldValidator, mandatory } from '../helper/validators';
import { ReactNode } from 'react';
import { FormFieldLabel } from './FormFieldLabel';

type RadioGroupOption = {
  value: string;
  label: string;
};

type RadioGroupAdapterProps = {
  name: string;
  label: string;
  options: RadioGroupOption[];
  disabled?: boolean;
  required?: boolean;
  validators?: FieldValidator<string>[];
  infoTooltipContent?: ReactNode;
};

export const RadioGroupAdapter = ({
  name,
  label,
  options,
  disabled = false,
  required = false,
  validators = [],
  infoTooltipContent,
}: RadioGroupAdapterProps) => {
  const { input, meta } = useField(name, { validate: composeValidators([required && mandatory, ...validators]) });
  const formMode = useFormMode();
  const currentValue = input.value;
  const displayError = Boolean((meta.error || meta.submitError) && meta.touched);

  const handleRadioChange = (nextValue: string) => {
    input.onChange(nextValue);
  };

  const touchField = () => {
    input.onBlur();
  };

  return (
    <FormControl error={displayError} variant="standard" fullWidth sx={{ marginBottom: 1 }}>
      <InputLabel shrink>
        <FormFieldLabel label={label} required={required} infoTooltipContent={infoTooltipContent} />
      </InputLabel>
      <RadioGroup
        sx={{ marginTop: 1 }}
        row
        name={name}
        value={currentValue}
        onBlur={touchField}
        onChange={(e) => handleRadioChange(e.target.value)}
        data-testid={`${name}-field`}
      >
        {options.map((opt) => (
          <FormControlLabel
            data-testid={`${name}-field-${opt.value}`}
            key={opt.value}
            value={opt.value}
            control={<Radio size="small" />}
            label={opt.label}
            disabled={isDisabledOrReadFormMode(disabled, formMode)}
          />
        ))}
      </RadioGroup>
      {displayError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
    </FormControl>
  );
};

import { PmuUserProfile } from './pmuUserTypes';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import { CurrentPmuApplicationUserDetails, CurrentPmuUserDetails } from '../security/types';
import { PmuAgency, PmuRegionalDirection } from '../common-types/direction/pmuDirectionTypes';

export const pmuProfileDisplayLabels: Record<PmuUserProfile, string> = {
  [PmuUserProfile.PMU_ADMIN]: 'Administrateur',
  [PmuUserProfile.PMU_NATIONAL]: 'National',
  [PmuUserProfile.PMU_REGIONAL_DIRECTION_MANAGER]: 'Responsable Gestion Partenaire',
  [PmuUserProfile.PMU_SEDENTARY_BUSINESS_MANAGER]: 'Chargé commercial sédentaire',
};

export const isPmuUser = (user: CurrentPmuApplicationUserDetails): user is CurrentPmuUserDetails =>
  user.company === UserCompany.PMU;

export const currentUserAgency = (user: CurrentPmuApplicationUserDetails): PmuAgency | undefined => {
  if (isPmuUser(user)) {
    return user.agency ?? undefined;
  }
  return undefined;
};

export const currentUserRegionalDirection = (
  user: CurrentPmuApplicationUserDetails,
): PmuRegionalDirection | undefined => {
  if (isPmuUser(user)) {
    return user.regionalDirection ?? undefined;
  }
  return undefined;
};
